<template>
  <div class="body fixed mobile">
    <!-- class: fixed, mobile -->
    <!-- <label for="locale">locale</label>
        <select v-model="locale">
            <option>en</option>
            <option>ja</option>
        </select>
        <p>message: {{ $t("hello") }}</p> -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->

    <section class="header index">
      <a class="left" @click.prevent="$router.push('/record')">
        <img class="logo" src="@/assets/img/favicon.svg" />
        <span class="primary">{{ displayUserPointBalance }}</span>
      </a>
      <div class="uide-page-name">縣政互動</div>
      <div class="right">
        <!-- <a class="icon iconButton" @click.prevent="barcodePopupOpen = true"> -->
        <a class="icon iconButton" @click.prevent="$router.push('/barcode')">
          <!-- <font-awesome-icon icon="fa-solid fa-barcode" size="xl"></font-awesome-icon> -->
          <img src="@/assets/img/barcode.svg" />
        </a>
        <NotificationBell
          v-if="triggerCountUnread"
          @count-unread="countUnread = $event"
        />
      </div>
    </section>
    <!-- Main -->
    <section class="main service">
      <div class="uide-service-title">政府宣導</div>
      <div class="top flexH width between">
        <a class="w-49" @click.prevent="$router.push('/B1')">
          <img class="w-full" src="@/assets/icon/receipt.svg" />
        </a>
        <a class="w-49" @click.prevent="$router.push('/B2')">
          <img class="w-full" src="@/assets/icon/checkin-v2.svg" />
        </a>
      </div>
      <div class="uide-service-title">健康運動專區</div>
      <div class="uide-service-board-wrap rounded-bg1">
        <div class="gap-3 flex justify-between">
          <a
            @click.prevent="linkClickHandler('https://bit.ly/4eximCf')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1196.svg" /></div>
            <div class="uide-service-button-text">樂齡健康學習平台</div>
          </a>
          <a
            @click.prevent="linkClickHandler('https://bit.ly/3MTS8Oq')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1197.svg" /></div>
            <div class="uide-service-button-text">健康活動參與平台</div>
          </a>
          <a
            class="uide-service-button-wrap"
            @click.prevent="$router.push('/healthy-step')"
          >
            <div><img src="@/assets/icon/uide/Frame1198.svg" /></div>
            <div class="uide-service-button-text">健康計步</div>
          </a>
        </div>
        <div class="gap-3 flex justify-between">
          <a
            @click.prevent="linkClickHandler('https://bit.ly/4dnD0ng')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1212.svg" /></div>
            <div class="uide-service-button-text">雲林健康步道</div>
          </a>
          <div class="uide-service-button-wrap"></div>
          <div class="uide-service-button-wrap"></div>
        </div>
      </div>
      <div class="uide-service-title">縣民服務</div>
      <div class="uide-service-board-wrap rounded-bg2">
        <div class="gap-3 flex justify-between">
          <a
            class="uide-service-button-wrap"
            @click.prevent="$router.push('/parking-coupons')"
          >
            <div><img src="@/assets/icon/uide/Frame1200.svg" /></div>
            <div class="uide-service-button-text">停車繳費</div>
          </a>
          <RouterLink class="uide-service-button-wrap" to="/ecotableware">
            <div><img src="@/assets/icon/uide/Frame1201.svg" /></div>
            <div class="uide-service-button-text">環保餐具租借與任務</div>
          </RouterLink>
          <a
            @click.prevent="linkClickHandler('https://bit.ly/4eb63fd')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1202.svg" /></div>
            <div class="uide-service-button-text">線上申辦</div>
          </a>
          
        </div>
        <div class="gap-3 flex justify-between">
        <RouterLink class="uide-service-button-wrap" to="/b11">
            <div><img src="@/assets/icon/uide/Frame1204.svg" /></div>
            <div class="uide-service-button-text">相關推薦</div>
          </RouterLink>
          <a
            @click.prevent="linkClickHandler('https://bit.ly/4exhvS3')"
            class="uide-service-button-wrap"
          >
            <div>
              <img
                width="48"
                height="49"
                src="@/assets/icon/uide/teacher-student.svg"
              />
            </div>
            <div class="uide-service-button-text">親師生平台</div>
          </a>
          <RouterLink class="uide-service-button-wrap" to="/digital-card">
            <div><img src="@/assets/icon/uide/digital-card-icon.svg" /></div>
            <div class="uide-service-button-text">數位圖書證</div>
          </RouterLink>
         
        </div>
      </div>
      <div class="uide-service-title">鄉土語言專區</div>
      <div class="uide-service-board-wrap rounded-bg3">
        <div class="gap-5 flex justify-between">
          <a
            @click.prevent="linkClickHandler('https://bit.ly/47EztQi')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1207.svg" /></div>
            <div class="uide-service-button-text">詔安客語</div>
          </a>
          <a
            @click.prevent="linkClickHandler('https://bit.ly/3zqGQhD')"
            target="_blank"
            class="uide-service-button-wrap"
          >
            <div><img src="@/assets/icon/uide/Frame1208.svg" /></div>
            <div class="uide-service-button-text">原住民族語</div>
          </a>
          <a
            class="uide-service-button-wrap"
            href="javascript:void(0)"
            style="pointer-events: none"
          >
            &nbsp;
          </a>
        </div>
      </div>
    </section>
    <section id="popLeave" class="popup">
      <div class="popBody">
        <a class="close" @click.prevent="closeLeaveModal"
          ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
        ></a>
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div class="fL text-center">提示</div>
          <div class="icon"></div>
          <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
        </section>
        <section class="popFooter flex">
          <a
            class="button bg-transparent text-black border border-primary flex-1 submit rounded"
            @click.prevent="closeLeaveModal"
            >返回</a
          >
          <a class="button flex-1 submit rounded" @click.prevent="goOut()"
            ><span class="fM">確認</span></a
          >
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import NotificationBell from "@/components/NotificationBell.vue";

export default {
  name: "Service",
  components: {
    NotificationBell,
  },
  data() {
    // this.$i18n.locale = "en";
    return {
      // locale: "en",
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      triggerCountUnread: false,
      campaigns: [],
      nextUrl: "",
    };
  },
  activated() {
    this.triggerCountUnread = true;
  },
  deactivated() {
    this.noQAPopupClose();
    this.getPointPopupClose();
    this.barcodePopupOpen = false;
    this.triggerCountUnread = false;
    // this.closeIntro();
  },
  mounted() {
    this.uploadSteps();
    this.$store.dispatch("getUserPoints");
    // this.getCampaigns();
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    ...mapGetters({
      userPoints: "getTyUserPoints",
    }),
    // qnaCampaigns() {
    //   return this.campaigns.filter(
    //     ({ campaignPrototype }) => campaignPrototype === "qna"
    //   );
    // },
    // voteCampaigns() {
    //   return this.campaigns.filter(
    //     ({ campaignPrototype }) => campaignPrototype === "vote"
    //   );
    // },
    // questionnaireCampaigns() {
    //   return this.campaigns.filter(
    //     ({ campaignPrototype }) => campaignPrototype === "questionnaire"
    //   );
    // },
    // numberOfQna() {
    //   return this.qnaCampaigns.length;
    // },
    // numberOfVote() {
    //   return this.voteCampaigns.length;
    // },
    // numberOfQuestionnaire() {
    //   return this.questionnaireCampaigns.length;
    // },
    // displayedQna() {
    //   return this.qnaCampaigns.slice(0, 3);
    // },
    // displayedVote() {
    //   return this.voteCampaigns.slice(0, 3);
    // },
    // displayedQuestionnaire() {
    //   return this.questionnaireCampaigns.slice(0, 3);
    // },
    displayUserPointBalance() {
      return this.userPoints && this.userPoints.pointBalance
        ? this.userPoints.pointBalance
        : 0;
    },
    isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },
  methods: {
    uploadSteps() {
      const payload = {
        type: "upload_steps",
      };
      window.Yunlin?.postMessage(JSON.stringify(payload));
      console.log("JS Interface", payload);
    },
    goOut() {
      this.closeLeaveModal();
      window.open(this.nextUrl, "_blank");
    },
    linkClickHandler(url) {
      this.openLeaveModal();
      this.nextUrl = url;
    },
    openLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "flex";
    },
    closeLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "none";
    },
    // getCampaignAPI() {
    //   let config = {
    //     url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/participations/uuid/${this.user.userId}/member_id/${this.userInfo.memberId}?includeNonJoined=true&includeInactive=true&publisherType=municipal`,
    //     method: "GET",
    //   };
    //   return this.$http(config);
    // },
    // getCampaigns() {
    //   return this.getCampaignAPI().then((res) => {
    //     this.campaigns = res.data.campaigns.filter(
    //       ({ campaignVisibility }) => campaignVisibility == "public"
    //     );
    //     this.sortCampaigns();
    //   });
    // },
    // sortCampaigns() {
    //   let incompleteCampaigns = this.campaigns.filter(
    //     (c) => c.completeness == "incomplete"
    //   );
    //   let notParticipatingCampaigns = this.campaigns.filter(
    //     (c) => c.completeness == "not-participating"
    //   );
    //   let completedCampaigns = this.campaigns.filter(
    //     (c) => c.completeness == "completed"
    //   );

    //   function sortByTsDesc(b, a) {
    //     return a.campaignStartTime - b.campaignStartTime;
    //   }
    //   incompleteCampaigns.sort(sortByTsDesc);
    //   notParticipatingCampaigns.sort(sortByTsDesc);
    //   completedCampaigns.sort(sortByTsDesc);
    //   this.campaigns = incompleteCampaigns
    //     .concat(notParticipatingCampaigns)
    //     .concat(completedCampaigns);
    // },
  },
};
</script>

<style scoped>
a {
  color: black;
}
.rounded-bg1 {
  background-color: #ece5ff; /* Change this to your desired color */
  border-radius: 20px; /* Adjust the radius as needed */
  padding: 10px; /* Optional: Add padding for better appearance */
}
.rounded-bg2 {
  background-color: #ece5ff; /* Change this to your desired color */
  border-radius: 20px; /* Adjust the radius as needed */
  padding: 10px; /* Optional: Add padding for better appearance */
}
.rounded-bg3 {
  background-color: #ece5ff; /* Change this to your desired color */
  border-radius: 20px; /* Adjust the radius as needed */
  padding: 10px; /* Optional: Add padding for better appearance */
}
</style>

<i18n>
{
  "en": {
    "hello": "hello world!666"
  },
  "ja": {
    "hello": "こんにちは、世界！666"
  }
}
</i18n>
